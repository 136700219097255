<mat-form-field class="product">
  <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.PRODUCT' | translate }}</mat-label>

  <textarea matInput
            [rows]="1"
            [(ngModel)]="product.product"
            (ngModelChange)="filterAutocomplete()"
            [matAutocomplete]="autocomplete"
            [disabled]="hygieneInspectionDisabled"
            cdkTextareaAutosize
            hpmAreaChangeListener>


  </textarea>
</mat-form-field>

<mat-autocomplete #autocomplete (optionSelected)="onAutocompleteSelected($event.option.value)">
  <mat-option *ngFor="let productProposal of filteredProductProposals" [disabled]="hygieneInspectionDisabled"
              [value]="productProposal.id">
    <div [innerHTML]="multiline(productProposal.name)"></div>
    @if (isAmbiguous(productProposal.name)) {
      {{ productProposal.efficiency }}
    }
  </mat-option>
</mat-autocomplete>
