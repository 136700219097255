@for (product of detail.products; track product) {
  <div class="product-contact-time-container">

    <hpm-product-field
      [product]="product"
      [area]="area"
      [questionId]="questionId"
      [hygieneInspectionDisabled]="hygieneInspectionDisabled"
      (selectedProductProposal)="onProductProposalSelected($index, $event)"
    ></hpm-product-field>
    <hpm-contact-time-field
      [(applicationTime)]="product.applicationTime"
      [productProposal]="productProposals[$index]"
      [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    ></hpm-contact-time-field>

    <button class="button-remove" (click)="removeLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="addLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>add</mat-icon>
    </button>
  </div>
}
