@for (product of detail.products; track product) {
  <div class="product-list-doses-container">

    <hpm-product-field [product]="product"
                       [area]="area"
                       [questionId]="questionId"
                       (selectedProductProposal)="onProductProposalSelected($index, $event)"
                       [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    ></hpm-product-field>

    <hpm-contact-time-field
      [(applicationTime)]="product.applicationTime[0]"
      [productProposal]="productProposals[$index]"
      [doseTypeFilter]="'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.DOSE_LOW_SEBACEOUS_GLAND' | translate"
      [customLabel]="'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.DOSE_LOW_SEBACEOUS_GLAND'"
      [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    ></hpm-contact-time-field>

    <hpm-contact-time-field
      [(applicationTime)]="product.applicationTime[1]"
      [productProposal]="productProposals[$index]"
      [doseTypeFilter]="'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.DOSE_HIGH_SEBACEOUS_GLAND' | translate"
      [customLabel]="'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.DOSE_HIGH_SEBACEOUS_GLAND'"
      [hygieneInspectionDisabled]="hygieneInspectionDisabled"
    ></hpm-contact-time-field>
    <button class="button-remove" (click)="removeLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="addLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>add</mat-icon>
    </button>
  </div>
}
