@for (product of detail.products; track product) {
  <div class="product-list-container">

    <hpm-product-field [area]="area" [questionId]="questionId"  [hygieneInspectionDisabled]="hygieneInspectionDisabled"
                       [product]="product">
    </hpm-product-field>

    <button class="button-remove" (click)="removeLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="addLine($index)" mat-icon-button [disabled]="hygieneInspectionDisabled">
      <mat-icon>add</mat-icon>
    </button>
  </div>
}
