export const environment = {
  baseUrl: 'https://hpm.dev-bbrauncloud.com/backend',
  selectedLocale: 'de',
  gitHubRefNumber: '912',
  gppURL: 'https://myprofile.test.account.bbraun.com/',
  msalConfig: {
    auth: {
      clientId: '02df2470-7fac-48b5-9cb7-2d5a5d0264b6',
      authority:
        'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/',
      knownAuthorities: ['https://test.account.bbraun.com/'],
    },
  },
  apiConfig: {
    scopes: ['https://test.account.bbraun.com/auth/gpr/User.Read'],
    uri: 'https://api.myprofile.test.account.bbraun.com/api/me',
  },
  customApiConfig: {
    scopes: [
      'https://test.account.bbraun.com/181a56d3-87d7-404d-bc2d-77f414e3d3a8/Read',
    ],
    uri: 'https://hpm.dev-bbrauncloud.com/backend',
  },
};
