<div class="contact-time">
<mat-form-field>
  @if (customLabel) {
    <mat-label>{{ customLabel | translate }}</mat-label>
  } @else {
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT_TIME' | translate }}</mat-label>
  }

  <textarea
  matInput
  class="dose-input"
  [(ngModel)]="applicationTime"
  (ngModelChange)="filterAutocompleteOptions()"
  [matAutocomplete]="autocomplete"
  [disabled]="hygieneInspectionDisabled"
  (blur)="applicationTimeChange.emit(applicationTime)"
  cdkTextareaAutosize
  hpmAreaChangeListener
  ></textarea>

</mat-form-field>

<mat-autocomplete #autocomplete>
  <mat-option *ngFor="let dose of filteredDoses"   [disabled]="hygieneInspectionDisabled"
              [value]="singleline(dose.dose)">

    <div [innerHTML]="multiline(dose.dose)"></div>
  </mat-option>
</mat-autocomplete>
</div>
